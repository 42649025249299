import type { RouteLocationNormalized } from "vue-router";

type CastingFunction = typeof Number | typeof String | typeof Boolean;

const castRouteParams = ( mapping: Record<string,CastingFunction> ) => (route: RouteLocationNormalized) => {
	const newParams = Object.entries(route.params).map( ([name,value]) => {
		if ( Array.isArray(value) || !( name in mapping ) ) {
			return [ name, value ];
		}
		return [ name, mapping[name](value) ];
	});
	
	return Object.fromEntries(newParams);
}

export default castRouteParams;