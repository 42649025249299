import { defineStore, acceptHMRUpdate } from 'pinia';
import { ref, unref, computed } from 'vue';

import axiosAuth from '@/util/axiosAuth.js';
import axiosAPI from '@/util/axiosAPI.js';

import { USER_ROLE, type UserRole } from "@billtrackpro-wilsonelser/api/enums";
export type { UserRole } from "@billtrackpro-wilsonelser/api/enums";

type UserData = {
	id: number,
	name: string,
	email: string,
	roles: UserRole[],
	attorney?: string;
	delegates?: string[];
	offices?: string[];
	vendors?: number[];
}

export interface User extends UserData {
	superUser?: UserData;
}

export interface LoginCredentials {
	email: string,
	password: string,
}
export interface PasswordResetAccess {
	recoveryToken: string;
	email: string;
	expires: string;
}

export const useAuthStore = defineStore( 'auth', () => {
	const user = ref<User>();

	try {
		const userItem = window.localStorage.getItem('user');
		if ( userItem ) {
			user.value = JSON.parse(userItem) as User;
		}
	}
	catch(err) {
		console.error( "Could not parse user from session storage. Clearing value." );
		clearLogin();
	}

	// check for and remove any expired (i.e. > 5 sec) refresh token request flag
	const refreshFlag = window.localStorage.getItem('refreshTokenStart');
	if ( refreshFlag ) {
		console.debug( `⏱ Found refresh token request flag on auth store init: ${refreshFlag}` );
		const epochRefresh = Date.parse(refreshFlag);
		if ( isNaN(epochRefresh) || epochRefresh < Date.now() - 5_000 ) {
			window.localStorage.removeItem('refreshTokenStart');
			console.debug( `⏱ Removed refresh token request flag` );
		}
	}

	function saveLogin( data: User ) {
		user.value = data;
		window.localStorage.setItem( 'user', JSON.stringify(user.value) );
	}
	function clearLogin() {
		user.value = undefined;
		window.sessionStorage.removeItem("termsAccepted");
		window.localStorage.removeItem('user');
	}
	function updateUser( name: string, email: string ) {
		user.value!.name = name;
		user.value!.email = email;
		window.localStorage.setItem( 'user', JSON.stringify(user.value) );
	}

	async function loadFromCookie() {
		try {
			const response = await axiosAPI.post<User>( '/whoami' );
			saveLogin(response.data);
			return response;
		}
		catch(err) {
			console.debug( "Could not load user based on access token" );
		}
	}

	async function login( credentials: LoginCredentials ) {
		try {
			const response = await axiosAuth.post<User>( '/login', credentials );
			saveLogin(response.data);
			return response;
		}
		catch( err ) {
			clearLogin();
			throw err;
		}
		finally {
			window.localStorage.removeItem('refreshTokenStart');
		}
	}
	async function logout() {
		await axiosAuth.post( '/logout' );
		clearLogin();
	}

	async function refresh() {
		try {
			window.localStorage.setItem( 'refreshTokenStart', new Date().toISOString() );
			const response = await axiosAuth.post<User>( '/refresh' );
			saveLogin(response.data);
			return response;
		}
		catch( err ) {
			clearLogin();
			// console.error( "Cannot log in with refresh token", err );
			throw err;
		}
		finally {
			window.localStorage.removeItem('refreshTokenStart');
		}
	}

	const isRefreshing = () => window.localStorage.getItem('refreshTokenStart') !== null;


	const isSuperUser = computed( () => user.value?.roles.includes(USER_ROLE.SUPER_USER) || user.value?.superUser !== undefined );

	async function mimic( userID: number | string ) {
		try {
			const response = await axiosAuth.post<User>( `/mimic/${userID}` );
			saveLogin(response.data);
			return response;
		}
		catch( err ) {
			clearLogin();
			throw err;
		}
		finally {
			window.localStorage.removeItem('refreshTokenStart');
		}
	}	


	function allow( ...allowed: ( UserRole | 'VENDOR:REVIEW' )[] ) {
		const currentUser = unref(user);

		if ( !currentUser ) {
			console.error( "No active user session" );
			return false;
		}
		
		return allowed.some( entry => {
			if ( entry === "VENDOR:REVIEW" ) {
				// 41: Edgar Anderson
				// 8643: Jonathan Chan
				// 12: Alan Rubin
				return [ 41, 8643, 12 ].includes(currentUser.id);
			}
			else {
				return currentUser.roles.includes(entry);
			}
		});
	}


	const passwordResetAccess = ref<PasswordResetAccess>();

	return {
		user,
		updateUser,
		loadFromCookie,
		login,
		logout,
		refresh,
		isRefreshing,

		isSuperUser,
		mimic,

		allow,
		
		passwordResetAccess,
	}
});

if ( import.meta.hot ) {
	import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}