import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router';

import { USER_ROLE } from '@billtrackpro-wilsonelser/api/enums';
import { useAuthStore, type UserRole } from '@/store/auth.js';
import setDocumentTitle from '@/util/setDocumentTitle.js';

import authRoutes from '../modules/auth/auth.routes.js';
import registerRoutes from '../modules/register/register.routes.js';
import vendorRoutes from '../modules/vendor/vendor.routes.js';
import adminRoutes from '../modules/admin/admin.routes.js';

declare module 'vue-router' {
	interface RouteMeta {
		title?: string;
		subtitle?: string;
		noAuthRequired?: boolean;
		allowedRoles?: UserRole[];
	}
}


const routes: RouteRecordRaw[] = [
	...authRoutes,
	...registerRoutes,
	...vendorRoutes,
	...adminRoutes,

	{
		path: '/',
		name: "AppHome",
		redirect(to) {
			const authStore = useAuthStore();
			if ( !authStore.user ) {
				return { name: 'AppLogin' }
			}

			if ( authStore.allow(USER_ROLE.VENDOR) ) {
				return { name: 'VendorInvoiceDataview' }
			}
			else if ( authStore.allow(USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER) ) {
				return { name: 'AdminInvoiceDataview' }
			}
			else if ( authStore.allow(USER_ROLE.ICJ) ) {
				return { name: "BTPRedirect", query: { to: "/admin/icj" }};
			}
			else {
				return { name: 'NotAuthorized' }
			}
		},
	},

	{
		path: '/account',
		name: "AccountSettings",
		redirect(to) {
			const authStore = useAuthStore();
			if ( !authStore.user ) {
				return { name: 'AppLogin', query: { redirect: '/account' } }
			}

			if ( authStore.allow(USER_ROLE.VENDOR) ) {
				return { name: 'VendorAccountSettings' }
			}
			else if ( authStore.allow(USER_ROLE.ADMIN, USER_ROLE.BUSINESS_USER, USER_ROLE.ATTORNEY, USER_ROLE.DELEGATE, USER_ROLE.OFFICE_MANAGER) ) {
				return { name: 'AdminAccountSettings' }
			}
			else {
				return { name: 'NotAuthorized' }
			}
		},
	},

	{
		path: '/lookup',
		name: "Lookup",
		component: () => import('../modules/lookup/LookupContainer.vue'),
		meta: {
			title: "Lookup",
			noAuthRequired: true,
		}
	},

	{
		path: '/go',
		name: "BTPRedirect",
		component: () => import('./BTPRedirect.vue'),
		meta: {
			title: "Redirecting...",
		}
	},

	{
		path: '/denied',
		name: "NotAuthorized",
		component: () => import('./NotAuthorized.vue'),
		meta: {
			title: "Not Authorized",
			noAuthRequired: true,
		}
	},
	{
		path: '/:missingPath(.*)*',
		component: () => import('./NotFound.vue'),
		meta: {
			noAuthRequired: true,
		},
		/*
		beforeEnter: (to) => {
			// TODO: implement redirect to an actual 404 error
			window.location.assign(`/404.html?target=${to.fullPath}`);
			return false;
		},
		*/
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior( to, from, savedPosition ) {
		if ( savedPosition ) {
			return savedPosition
		}
		else {
			switch( to.name ) {
				case "AdminInvoiceReviewHardcopy":
					return new Promise( (resolve, reject) => {
						setTimeout( () => resolve({ el: '#invoice-details', top: -10, behavior: "smooth" }), 500 );
					});
				
				case "AdminInvoiceReviewItems":
				case "AdminInvoiceReviewConfirm":
				case "AdminInvoiceForwardConfirm":
					return { el: '#invoice-details', top: -10, behavior: "smooth" }

				case "AdminInvoiceForwardMethodContact":
					return { el: '#invoice-details', top: -10, behavior: "smooth" }

				case "AdminInvoiceForwardMessage":
					return { el: '#forward-message', top: -2, behavior: "smooth" }

				case "AdminInvoiceForwardAttachments":
					return { el: '#forward-attachments', top: -2, behavior: "smooth" }
			}

			return { top: 0 }
		}
	},
});


router.beforeEach( async (to,from) => {
	let docTitle = to.meta.title;
	if ( docTitle && typeof docTitle === "string" ) {
		if ( to.meta.subtitle && typeof to.meta.subtitle === "string" ) {
			docTitle += ' - ' + to.meta.subtitle;
		}
		setDocumentTitle( docTitle );
	}
	
	// confirm navigation if no authentication is required
	if ( to.meta.noAuthRequired === true ) {
		return true;
	}

	// authentication is required; check for a logged-in user (try using an access token cookie as a backup)
	const authStore = useAuthStore();

	if ( !authStore.user ) {
		await authStore.loadFromCookie();

		if ( !authStore.user ) {
			console.error( `Authentication required for ${to.path}. Redirecting to login...` );
			return { name: "AppLogin", query: { redirect: `${router.options.history.base}${to.fullPath}` }};
		}
	}

	// user is authenticated; check for authorization
	const { allowedRoles } = to.meta;
	if ( allowedRoles === undefined || authStore.allow(...allowedRoles) ) {
		return true;
	}
	
	return { name: "NotAuthorized", query: { target: `${router.options.history.base}${to.fullPath}` } };
});

export default router
