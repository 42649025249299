<template>
	<RouterView></RouterView>

	<Transition name="fade">
		<AppErrorAlert ref="errorAlert"
			:error="appError" 
			@close="closeError"
			v-if="appError" 
			/>
	</Transition>
</template>

<script setup lang="ts">
	import { ref, onErrorCaptured } from 'vue';
	import { useRouter } from 'vue-router';

	import { isAxiosError } from 'axios';
	import AppErrorAlert from "@/components/AppErrorAlert.vue";

	const router = useRouter();
	const appError = ref<Error>();

	function closeError() {
		appError.value = undefined;
	}

	onErrorCaptured( ( err, vm, info ) => {
		if ( isAxiosError(err) && err.response?.status === 401 ) {
			router.push({
				name: "AppLogin",
				query: {
					redirect: router.currentRoute.value.fullPath,
				},
			});
		}
		else {
			appError.value = err;
		}

		return false;
	});
</script>