<template>
	<svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox" class="w-auto h-[1.25em]" :class="{ 'inline-block': inline }" :style="cssVars">
		<circle :cx="( size / 2 )" :cy="( size / 2 )" :r="( ( size - strokeWidth ) / 2 )"></circle>
	</svg>
</template>

<script setup lang="ts">
	import { computed, type CSSProperties } from 'vue';

	type ComponentProps = {
		size?: number;
		strokeWidth?: number;
		inline?: boolean;
	}
	const props = withDefaults( defineProps<ComponentProps>(), {
		size: 50,
		strokeWidth: 4,
		inline: false,
	});

	const cssVars = computed( (): CSSProperties => ({
		'--size': `${props.size}px`,
		'--stroke': props.strokeWidth,
	}) );

	const viewBox = computed( () => `0 0 ${props.size} ${props.size}` );
</script>

<style scoped>
	svg circle {
		fill: transparent;
		stroke: currentColor;
		stroke-width: var(--stroke);
		stroke-linecap: round;
		stroke-dasharray: calc(3.14 * var(--size));
		transform-origin: 50% 50% 0;
		animation: spinner 2s linear infinite;
	}

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
			stroke-dashoffset: calc(0.66 * var(--size));
		}
		50% {
			transform: rotate(720deg);
			stroke-dashoffset: calc(3.14 * var(--size));
		}
		100% {
			transform: rotate(1080deg);
			stroke-dashoffset: calc(0.66 * var(--size));
		}
	}
</style>