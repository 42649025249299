import axios from "axios";

const axiosAuth = axios.create({ baseURL: import.meta.env.VITE_APP_ROOT_AUTH });

axiosAuth.interceptors.request.use( req => {
	// console.log( `${req.method} ${req.url}` );
	return req;
})

export default axiosAuth;
