import type { NavigationGuardWithThis } from "vue-router";

import { useDataviewStore } from '@/store/dataview.js';

const enterDataView: NavigationGuardWithThis<undefined> = (to,from) => {
	const dataviewStore = useDataviewStore();
	const savedQuery = dataviewStore.savedQueryParams.get(to.path);
	
	console.debug( `🤚🏻 [${String(to.name)}].beforeEnter() ... targeting ${to.fullPath}; ${JSON.stringify(savedQuery)} saved` );
	
	if ( Object.keys(to.query).length === 0 && savedQuery !== undefined && Object.keys(savedQuery).length ) {
		console.debug( `🤚🏻 [${String(to.name)}].beforeEnter() ... no query params requested; 🔼 rewrite target to saved params ${JSON.stringify(savedQuery)}` );
		return { path: to.path, query: savedQuery };
	}
	
}

export default enterDataView;