<template>
	<div class="container-lock z-50 flex flex-col px-3" @keydown.esc="close">
		
		<div class="grow" style="max-height: 20vh"></div>

		<div id="error-modal" ref="$modal" 
			tabindex="-1" 
			class="mx-auto max-w-md sm:max-w-3xl sm:w-4/5 max-h-[80vh] outline-0 bg-white overflow-hidden flex flex-col rounded-lg drop-shadow-md border-2 border-red-700" 
			>
			<header class="flex-none px-4 py-2 bg-red-500 text-white">
				
				<iconify-icon icon="mdi:close" class="absolute top-2 right-2 block text-white text-base cursor-pointer" height="1.25rem" @click.prevent="close" aria-label="Close" />

				<div class="w-full grid items-center gap-3" style="grid-template-columns: auto calc( 100% - 1rem - 2.25rem );">
					<iconify-icon icon="mdi:close-circle" class="text-5xl" />

					<div class="font-heading font-medium">
						<template v-if="axiosResponse?.status">Error {{ axiosResponse.status }}: {{ axiosResponse.statusText }}</template>
						<template v-else-if="axiosConfig || axiosResponse">Request Error</template>
						<template v-else>Application Error</template>
					</div>
				</div>

			</header>
			<div class="flex-initial overflow-hidden px-4 py-3 flex flex-col">

				<div class="flex-none text-sm font-mono text-black/60" v-if="axiosConfig">
					{{ axiosConfig.method?.toUpperCase() }} {{ axiosConfig.url }}
				</div>

				<p class="flex-initial text-lg select-all mb-6 -mr-4 pr-4 overflow-auto mini-scrollbar">
					{{ errorMessage }}
				</p>

				<div class="flex-none flex items-center gap-3">
					<button @click="close" class="btn btn-default text-sm mr-auto">OK</button>

					<a href="#" class="flex items-center gap-1 text-neutral-500 hover:text-neutral-700 hover:no-underline" 
						@click.prevent="toggleDetails('request')" 
						title="Show request data" 
						v-if="axiosConfig?.data"
						>
						<iconify-icon :icon="iconRequest" height="1.25rem" />
						<small>Request Data</small>
					</a>

					<a href="#" class="flex items-center gap-1 text-neutral-500 hover:text-neutral-700 hover:no-underline" 
						@click.prevent="toggleDetails('error')" 
						title="Show error details" 
						v-if="axiosResponse?.data || stack?.length"
						>
						<iconify-icon :icon="iconError" height="1.25rem" />
						<small>Error Details</small>
					</a>
				</div>

			</div>
			<footer class="flex-initial max-h-[25vh] overflow-auto mini-scrollbar bg-neutral-100 border-t border-neutral-200 px-4 py-3 shadow-inner text-xs font-mono whitespace-pre-wrap break-words select-all" 
				v-show="showDetails"
				>
				<span v-show="showRequestDetails">
					{{ requestData }}
				</span>
				<span v-show="showErrorDetails" v-if="axiosResponse?.data">
					{{ JSON.stringify(axiosResponse.data,null,2) }}
				</span>
				<span v-show="showErrorDetails" v-else-if="error.stack">
					{{ error.stack }}
				</span>
			</footer>
		</div>

		<div class="grow-[2]"></div>
		
	</div>
</template>

<script setup lang="ts">
	import { ref, computed, onMounted } from 'vue';
	import { isAxiosError } from 'axios';

	const { error } = defineProps<{
		error: Error
	}>();
	const emit = defineEmits<{
		close: [];
	}>();

	const $modal = ref<HTMLDivElement | null>(null);

	onMounted( () => {
		$modal.value?.focus();
	});


	const stack = computed( () => error.stack?.split('\n') );

	const axiosConfig = computed( () => isAxiosError(error) ? error.config : undefined );
	const axiosResponse = computed( () => isAxiosError(error) ? error.response : undefined );

	const errorMessage = computed( () => {
		const responseData: any = axiosResponse.value?.data;
		if ( typeof responseData === "object" && responseData?.hasOwnProperty("message") ) {
			return responseData.message;
		}
		return error.message;
	});

	const requestData = computed( () => {
		if ( !isAxiosError(error) ) {
			return undefined;
		}

		let configData = axiosConfig.value?.data ?? "";
		
		try {
			if ( configData instanceof FormData ) {
				return Array.from( configData.entries() );
			}
			else {
				const data = JSON.parse(configData);
				return JSON.stringify( data, null, 2 );
			}
		}
		catch( err ) {
			return configData;
		}
	});


	type DetailView = 'request' | 'error'
	const showDetails = ref<DetailView | false>(false);

	const showRequestDetails = computed( () => showDetails.value === 'request' );
	const showErrorDetails = computed( () => showDetails.value === 'error' );
	const iconRequest = computed( () => showRequestDetails.value ? 'mdi:information-variant-circle' : 'mdi:information-variant-circle-outline' );
	const iconError = computed( () => showErrorDetails.value ? 'mdi:information-variant-circle' : 'mdi:information-variant-circle-outline' );

	function close() {
		emit('close');
	}

	function toggleDetails( type: DetailView ) {
		showDetails.value = showDetails.value === type ? false : type;
	}
</script>