if ( import.meta.env.MODE !== 'production' ) {
	console.debug( "Environment variables", import.meta.env );
}

// -------------------------

import 'iconify-icon';
import { addCollection } from 'iconify-icon';

import iconsBootstrap from './assets/icons/bi.json' with { type: "json" };
import iconsMaterial from './assets/icons/mdi.json' with { type: "json" };
import iconsFARegular from './assets/icons/fa-regular.json' with { type: "json" };
import iconsFluent from './assets/icons/fluent.json' with { type: "json" };
import iconsTabler from './assets/icons/tabler.json' with { type: "json" };
import iconsLineMD from './assets/icons/line-md.json' with { type: "json" };

addCollection(iconsBootstrap);
addCollection(iconsMaterial);
addCollection(iconsFARegular);
addCollection(iconsFluent);
addCollection(iconsTabler);
addCollection(iconsLineMD);

// -------------------------

import './assets/index.css';
import './assets/vue-transitions.css';

import { createApp } from 'vue';

import { createPinia } from 'pinia';
const pinia = createPinia();

import router from './router/index.js';

import App from './App.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

createApp(App)
	.component( 'LoadingSpinner', LoadingSpinner )
	.use( pinia )
	.use( router )
	.mount( '#app' );
