import type { RouteRecordRaw } from 'vue-router';

import { USER_ROLE } from '@billtrackpro-wilsonelser/api/enums';

const routes: RouteRecordRaw[] = [
	{
		path: '/register',
		name: 'VendorRegistration',
		component: () => import('./VendorRegistration.vue'),
		meta: {
			title: "Vendor Registration",
			noAuthRequired: true,
		}
	},
	{
		path: '/register/success',
		name: 'RegistrationSuccess',
		component: () => import('./RegistrationSuccess.vue'),
		meta: {
			title: "Vendor Registration",
			allowedRoles: [USER_ROLE.VENDOR],
		}
	},
];

export default routes;