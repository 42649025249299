import type { RouteRecordRaw } from 'vue-router';

const AppLogin = () => import('./AppLogin.vue');
const ForgotPassword = () => import('./ForgotPassword.vue');
const ValidateResetPassword = () => import('./ValidateResetPassword.vue');
const ResetPassword = () => import('./ResetPassword.vue');

const routes: RouteRecordRaw[] = [
	{
		path: '/login',
		name: 'AppLogin',
		component: AppLogin,
		meta: {
			title: "Login",
		}
	},
	{
		path: '/login/forgot',
		name: 'ForgotPassword',
		component: ForgotPassword,
		meta: {
			title: "Forgot Password",
		}
	},
	{
		path: '/login/reset-password/:requestID/:token',
		props: true,
		name: 'ValidateResetPassword',
		component: ValidateResetPassword,
		meta: {
			title: "Validate Password Reset Request",
		}
	},
	{
		path: '/login/reset-password',
		name: 'ResetPassword',
		component: ResetPassword,
		meta: {
			title: "Reset Password",
		}
	},
];

routes.forEach( route => route.meta = {
	...route.meta,
	noAuthRequired: true,
});

export default routes;