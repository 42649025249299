export const APP_VERSION = {
    LDBS: 1,
    BTP: 2,
    BTP_PLUS: 3,
};
export const INVOICE_RESULT = {
    DRAFT: 1,
    PENDING_REVIEW: 2,
    APPROVED: 3,
    PARTIAL_APPROVED: 4,
    DECLINED: 5,
    DECLINED_REGISTRATION: 6,
};
export const APPROVED_RESULT = {
    PENDING_INTERNAL: 1,
    INTERNAL: 3,
    PENDING_FORWARD: 2,
    FORWARDED: 4,
};
/**
 * itemStatusCode in the InvoiceItems table
 *
 * - NULL = draft item
 * - values betwen 0x01 and 0x20 = approved
 * - values > 0x10 = pending action; highest value is most relevant
 */
export const ITEM_STATUS = {
    DECLINED: 0x0,
    PAID_INTERNALLY: 0x01,
    DELIVERED_CLIENT: 0x02,
    PENDING_DELIVERY: 0x10,
    PENDING_SPECIAL_HANDLING: 0x11,
    PENDING_CLIENT_DETAILS: 0x15,
    PENDING_FORWARDER_ASSIGNMENT: 0x16,
    DTC_OVERRIDE_REQUEST: 0x20,
    PENDING_REVIEW: 0x30,
    MISSING_REVIEWER: 0x31,
    PENDING_VENDOR_REVIEW: 0x32,
};
export const CLIENT_VENDOR_RULE_TYPE = {
    PREFERRED: 'P',
    REGULAR: 'R',
    PROHIBITED: 'X',
    ALLOWED: 'A',
};
export const TARGET_PAYOR_CODE = {
    /** Invoice paid by **Wilson Elser**. Invoice amount is below client thresholds and vendor is not Preferred */
    INTERNAL: 'IT',
    /** Invoice paid by **client**. Manual designation from LDBS era */
    CLIENT_LDBS: 'CL',
    /** Invoice paid by **client**. Invoice amount exceeds a client threshold rule */
    CLIENT_OVER_THRESHOLD: 'CT',
    /** Invoice paid by **client**. Invoice vendor is Preferred for this client */
    CLIENT_PREFERRED_VENDOR: 'CP',
    /** Invoice paid by **client**. Invoice should have been paid by Wilson Elser but reviewer chose to override */
    CLIENT_MANUAL_OVERRIDE: 'CM',
    /** Invoice paid by **Wilson Elser**. Invoice was supposed to be forwarded but admin overrode */
    INTERNAL_MANUAL: 'IM',
    /** Invoice payment **under review**. Invoice is over threshold but reviewer has requested A/P review to process internally */
    DTC_OVERRIDE_REQUEST: 'RI',
    /** Invoice paid by **Wilson Elser**. Reviewer requested override of client threshold rule and A/P approved */
    INTERNAL_APPROVE_DTC_OVERRIDE: 'IA',
    /** Invoice paid by **client**. Reviewer requested override of client threshold rule but A/P rejected */
    CLIENT_REJECT_DTC_OVERRIDE: 'CR',
};
export const USER_ROLE = {
    VENDOR: 'vendor',
    ATTORNEY: 'attorney',
    DELEGATE: 'delegate',
    OFFICE_MANAGER: 'office-manager',
    BUSINESS_USER: 'business-user',
    ADMIN: 'admin',
    ICJ: 'icj',
    SUPER_USER: 'super-user',
};
const userRoles = Object.values(USER_ROLE);
export function isUserRole(value) {
    return typeof value === "string" && userRoles.some(item => item === value);
}
export const VENDOR_REGISTRATION_STATUS = {
    PENDING_REVIEW: 'P',
    APPROVED: 'A',
    REJECTED: 'R',
    ACKNOWLEDGED: 'K',
};
export const EMAIL_LOG_TYPE = {
    VENDOR_APPROVED: "vendor-approved",
    TERMS_ACCEPTED: "terms-accepted",
    INVOICE_UPDATE: "invoice-update",
    FORWARD_CLIENT: "fwd-client",
    FORWARD_CLIENT_NOTIFY: "fwd-client-notify",
};
