import type { RouteRecordRaw, NavigationGuardWithThis } from 'vue-router';

import { USER_ROLE } from '@billtrackpro-wilsonelser/api/enums';
import enterDataview from '@/util/enterDataview.js';
import castRouteParams from '@/util/castRouteParams.js';
import axiosAPI from '@/util/axiosAPI.js';

// --- set up routes that require Terms and Conditions acceptance

const requireTermsAccept: NavigationGuardWithThis<undefined> = async (to) => {
	// console.debug( `Check if terms accepted before ${to.fullPath}` );
	let accepted: boolean;

	const termsAcceptedItem = window.sessionStorage.getItem("termsAccepted");

	if ( termsAcceptedItem === null ) {
		const response = await axiosAPI.get<Date | false>( '/vendors/self/terms' );
		accepted = response.data !== false;
		window.sessionStorage.setItem( "termsAccepted", String(response.data) );
	}
	else {
		accepted = termsAcceptedItem !== "false";
	}

	if ( !accepted ) {
		return { name: "AcceptTerms" }
	}
}

const routes: RouteRecordRaw[] = [
	{
		path: '/vendor',
		component: () => import('@/layouts/SiteContainer.vue'),
		children: [
			{
				path: "invoices",
				name: "VendorInvoiceDataview",
				component: () => import('./invoices/VendorInvoiceDataview.vue'),
				meta: {
					title: "Invoices",
				},
				beforeEnter: [ enterDataview ],
			},
			{
				path: "invoices/drafts",
				name: "VendorInvoiceDrafts",
				component: () => import('./invoices/VendorInvoiceDrafts.vue'),
				meta: {
					title: "Drafts",
				},
				beforeEnter: [ enterDataview ],
			},
			{
				path: "invoices/:invoiceID(\\d+)",
				name: "VendorInvoiceDetails",
				component: () => import('./invoices/view/VendorInvoiceDetails.vue'),
				props: castRouteParams({ invoiceID: Number }),
				meta: {
				},
			},
			{
				path: "submit",
				component: () => import('./submit/VendorInvoiceSubmit.vue'),
				children: [
					{
						path: "",
						name: "VendorInvoiceSubmit",
						component: () => import('./submit/VendorInvoiceSubmit1.vue'),
					},
					{
						path: "pdf",
						name: "VendorInvoiceSubmitPDF",
						component: () => import('./submit/VendorInvoiceSubmit2.vue'),
					},
					{
						path: "review",
						name: "VendorInvoiceSubmitReview",
						component: () => import('./submit/VendorInvoiceSubmit3.vue'),
					},
				],
				meta: {
					title: "Submit New Invoice",
				},
				beforeEnter: to => {
					if ( !to.query.id && to.name !== "VendorInvoiceSubmit" ) {
						return { name: "VendorInvoiceSubmit" };
					}
				}
			},
			{
				path: "account",
				name: "VendorAccountSettings",
				component: () => import('./account/AccountSettings.vue'),
				meta: {
					title: "Account Settings",
				},
			},
			{
				path: "support",
				name: "VendorSupport",
				component: () => import('./VendorSupport.vue'),
				meta: {
					title: "Help & Support",
				},
			},
			{
				path: "terms",
				name: "VendorTerms",
				component: () => import('./VendorTerms.vue'),
				meta: {
					title: "Terms & Conditions",
				},
			},
		],
		beforeEnter: [
			requireTermsAccept,
		],
	},

	{
		// --- the root-level Terms and Conditions route does not have the requireTermsAccept route guard
		path: '/terms',
		name: 'AcceptTerms',
		component: () => import('./AcceptTerms.vue'),
		meta: {
			title: "Terms and Conditions",
		}
	},
];


// --- require the 'vendor' role for all routes

routes.forEach( route => route.meta = {
	...route.meta,
	allowedRoles: [USER_ROLE.VENDOR],
});

export default routes;