import axios, { CanceledError, type AxiosRequestConfig } from 'axios';

import { useAuthStore } from '../store/auth.js';

export type * as ApiRequest from '@billtrackpro-wilsonelser/api/requests';
export type * as ApiResponse from '@billtrackpro-wilsonelser/api/responses';


function sleep( ms: number ) {
	return new Promise( resolve => setTimeout( resolve, ms ));
}

function resendRequest( origRequest: AxiosRequestConfig ) {
	if ( !origRequest.headers ) {
		origRequest.headers = {};
	}
	origRequest.headers['x-token-refresh-retry'] = true;
	return axios.request(origRequest);
}

const REFRESH_DELAY_MS = 500;

const axiosAPI = axios.create({ baseURL: import.meta.env.VITE_APP_ROOT_API });

axiosAPI.interceptors.response.use(
	response => response,
	async error => {
		const origRequest: AxiosRequestConfig = error.config;

		if ( error instanceof CanceledError ) {
			console.error( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Request has been canceled`, error );
			return Promise.reject(error);
		}
		else if ( error.response.status !== 401 && error.response.status !== 419 ) {
			return Promise.reject(error);
		}
		
		const authStore = useAuthStore();

		if ( origRequest.headers?.['x-token-refresh-retry'] ) {
			console.error( `${origRequest.method?.toUpperCase()} ${origRequest.url}: API authentication problem remains after retrying request.` );
			await authStore.logout();
			return Promise.reject(error);
		}
		
		if ( authStore.isRefreshing() ) {
			// a separate effort to refresh the access token is happening; wait for it to complete by checking every REFRESH_DELAY_MS milliseconds
			// the effort may be a request from this Vue instance, or from an instance in another browser tab
			let deferrals = 16;
			do {
				console.debug( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Waiting ${REFRESH_DELAY_MS}ms for access token to be refreshed. ${deferrals} deferral(s) remaining.` );
				await sleep(REFRESH_DELAY_MS);
			}
			while ( authStore.isRefreshing() && --deferrals );
			
			if ( !!authStore.user ) {
				console.debug( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Access token done being refreshed. Resending request...` );
				return resendRequest(origRequest);
			}
			else {
				console.debug( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Access token was not refreshed. Return rejected Promise.` );
				return Promise.reject(error);
			}
		}

		try {
			// try to use refresh token; if successful, retry the original request
			console.debug( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Attempting to refresh access token...` );
			await authStore.refresh();
			console.debug( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Successfully refreshed access token. Resending request...` );
			
			return resendRequest(origRequest);
		}
		catch ( errRefresh ) {
			console.error( `${origRequest.method?.toUpperCase()} ${origRequest.url}: Error refreshing access token.` );
			window.sessionStorage.setItem( 'refreshTokenError', new Date().toISOString() );
			return Promise.reject(error);
		}
	}
);

export default axiosAPI;