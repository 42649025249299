import { defineStore, acceptHMRUpdate } from 'pinia';
import { reactive } from 'vue';
import type { LocationQueryRaw } from 'vue-router';

export const useDataviewStore = defineStore( 'dataview', () => {

	const initQueryParamsMap: [ string, LocationQueryRaw ][] = [
		[ '/vendor/invoices', { sort: "-inv_date" } ],
		[ '/vendor/invoices/drafts', { sort: "-inv_date" } ],
		[ '/admin/invoices', { status: "pending", sort: "-submit_date" } ],
		[ '/admin/users/staff', { sort: "name" } ],
		[ '/admin/users/vendors', { sort: "status" } ],
		[ '/admin/vendors', { sort: "status" } ],
		[ '/admin/email-history', { sort: "-sent" } ],
	];
	const savedQueryParams = reactive( new Map<string,LocationQueryRaw>(initQueryParamsMap) );

	// set of dataview paths that must be reloaded when the component is reactivated
	const dirty = reactive( new Set<string>() );

	function saveQueryParams( section: string, params: Record<string,any> ) {
		// strip undefined values, so key count in util/enterDataView.ts is correct
		Object.keys(params).forEach( key => params[key] === undefined && delete params[key] );
		
		savedQueryParams.set( section, params );
	}

	return {
		savedQueryParams,
		saveQueryParams,

		dirty,
	}

});

if ( import.meta.hot ) {
	import.meta.hot.accept(acceptHMRUpdate(useDataviewStore, import.meta.hot));
}